/* サービスページのクリーニング部分 */
<template>
  <div class="base">
    <div class="one">
      <p>WET, DRY & CARE, REPAIR</p>
      <div class="inner first">
        <h2>洗濯・乾燥＆服の管理全般</h2>
        <h3>
          すべてのアイテムは
          <ul>
            <li>
              <img src="@/assets/service/check.svg" alt="チェック" />
              厳格なクリーニングプロセス
            </li>
            <li>
              <img src="@/assets/service/check.svg" alt="チェック" />
              補修プロセス
            </li>
          </ul>
          を経てあなたの元に送られる。
        </h3>
      </div>
    </div>
    <div class="two">
      <p>QUALITY ASSURANCE</p>
      <div class="inner second">
        <h2>品質補償</h2>
        <h3>
          すべてのアイテムは
          <ul>
            <li>
              <img src="@/assets/service/check.svg" alt="チェック" />
              優れた状態か？
            </li>
            <li>
              <img src="@/assets/service/check.svg" alt="チェック" />
              着用する準備ができているか？
            </li>
          </ul>
          厳密な検品検査を元に最善な状態でお届けできるよう行われている。
        </h3>
      </div>
    </div>
    <div class="three">
      <p>SEALED & SENT</p>
      <div class="inner third">
        <h2>包装＆発送</h2>
        <h3>
          服のライフサイクルを延ばすため
          <ul>
            <li>
              <img src="@/assets/service/check.svg" alt="チェック" />
              専用バッグで発送される。
            </li>
          </ul>
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Cleaning'
}
</script>

<style scoped>
.base {
  justify-content: space-between;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
}

.inner {
  border: 1px solid black;
  position: relative;
  border-radius: 15px;
}

.inner h2 {
  writing-mode: vertical-rl;
  position: absolute;
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: rgb(245, 245, 245);
}

.inner ul {
  list-style: none;
}

.inner ul li {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.inner img {
  width: 30px;
}

.inner h3 {
  text-align: left;
  margin-right: auto;
  margin-left: auto;
  font-weight: normal;
}

.base p {
  margin: 0;
  font-size: 13px;
  letter-spacing: 3px;
  font-weight: bold;
}

@media screen and (max-width: 950px) {
  .base {
    margin-top: 50px;
  }

  .one,
  .two,
  .three {
    margin-bottom: 50px;
  }

  .inner h3 {
    letter-spacing: 1px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .inner h2 {
    font-size: 18px;
    right: -12px;
    top: 10px;
  }

  .first {
    height: 280px;
  }

  .second {
    height: 240px;
  }

  .third {
    height: 150px;
  }

  .inner ul {
    padding-left: 0;
  }
}

@media screen and (min-width: 951px) {
  .base {
    display: flex;
    margin-top: 80px;
  }

  .inner h3 {
    letter-spacing: 4px;
    writing-mode: vertical-rl;
    padding-top: 40px;
  }

  .one,
  .two,
  .three {
    width: 30%;
  }

  .inner h2 {
    letter-spacing: 2px;
    right: -15px;
    top: 20px;
  }

  .inner {
    height: 450px;
  }

  .inner ul {
    margin-top: -30px;
    padding-top: 0;
  }
}
</style>
