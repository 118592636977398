/* サービスページの返送の仕方部分 */
<template>
  <div class="test">
    <div>
      <p>送られてきた専用バッグに入れ、近くのコンビニで発送しにいくだけ。</p>
    </div>
    <p>発送が面倒であれば集荷も対応可能。</p>
  </div>
</template>

<script>
export default {
  name: 'Return'
}
</script>

<style scoped>
.test p {
  background-color: white;
  display: inline-block;
  letter-spacing: 3px;
  padding: 10px 20px;
  box-shadow: 0px 15px 10px rgb(145, 145, 145);
}

.test {
  padding-bottom: 100px;
}

@media screen and (max-width:950px) {
  .test p {
    padding: 5px 10px;
    width: 85%;
  }
}
</style>
