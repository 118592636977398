/* サービスの使い方ページ */
<template>
  <div class="service">
    <div>
      <div class="title">
        <h1>サービスの使い方</h1>
        <h2>How it Works</h2>
      </div>
      <div class="description">
        <div class="list one">
          <p>Pick a Plan</p>
          <h2>プランを選択</h2>
          <div class="little a">
            毎週2着ずつ選び週末のお出かけに使用する事も一回に4着届け着回す事も可能。<br>
            全てはあなた自身に合ったスタイルであなたがお選びください。
          </div>
        </div>
        <div class="list two">
          <p>Choose Your Items</p>
          <h2>アイテムを選択</h2>
          <div class="little b">
            一度は着てみたかった服、買いたくても買えなかった服など<br>好きな服、気になる服をお選びください。
          </div>
        </div>
        <div class="list three">
          <p>Mix. Match. Wear. Repeat.</p>
          <h2>相性、マッチ、実感、リピート</h2>
          <div class="little c">
            届いた服を実際に着用し実感する。<br>
            この服相性良いかもと。<br>
            自身にマッチする服を再度探す旅のお供をします。<br>
            是非お楽しみください。
          </div>
        </div>
      </div>
      <div class="why">
        <h3>なぜ借りるのか</h3>
        <h2>Why</h2>
      </div>
      <div class="main">
        <div class="first">
          <img src="@/assets/service/serviceone.svg" alt="サービスアイコン" />
          <p>特別な日に</p>
          <h2>特別な日に</h2>
          <h3>特別な日に</h3>
        </div>
        <div class="second">
          <img src="@/assets/service/servicetwo.svg" alt="サービスアイコン" />
          <p>週末のお出かけに</p>
          <h2>週末のお出かけに</h2>
          <h3>週末のお出かけに</h3>
        </div>
        <div class="third">
          <img src="@/assets/service/servicethree.svg" alt="サービスアイコン" />
          <p>仕事に</p>
          <h2>仕事に</h2>
          <h3>仕事に</h3>
        </div>
      </div>
      <div class="cleaning">
        <div class="top">
          <h3>クリーニングプロセス</h3>
          <h2>Cleaning Process</h2>
        </div>
        <Cleaning />
      </div>
      <div class="return">
        <div class="titleR">
          <h3>返送の仕方</h3>
          <h2>How to Return</h2>
        </div>
        <Return />
        <div class="test" v-for="content in contents" :key="content.body">
          {{ content.body }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cleaning from '@/components/service/Cleaning.vue'
import Return from '@/components/service/Return.vue'
import firebase from '@/firebase/firebase.js'

export default {
  name: 'Service',
  components: {
    Cleaning,
    Return
  },
  data () {
    return {
      db: null,
      contents: []
    }
  },
  created () {
    this.db = firebase.firestore()
    this.syncContent()
  },
  methods: {
    syncContent () {
      const category = 'normal'
      this.contents.splice(0)
      this.db
        .collection('contents')
        .where('category', '==', category)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.contents.push(doc.data())
          })
        })
    }
  }
}
</script>

<style scoped>
.titleR h3 {
  font-size: 30px;
  letter-spacing: 3px;
  position: relative;
  z-index: 2;
}

.titleR h2 {
  color: white;
  position: absolute;
  top: -25px;
  left: 25%;
  margin: 0;
  z-index: 1;
  font-size: 50px;
  letter-spacing: 2px;
}

.titleR {
  position: relative;
  margin-top: 130px;
}

.top h3 {
  font-size: 30px;
  letter-spacing: 3px;
  position: relative;
  z-index: 2;
}

.top h2 {
  color: white;
  position: absolute;
  top: -25px;
  margin: 0;
  z-index: 1;
}

.top {
  position: relative;
  margin-top: 130px;
}

.main img {
  width: 70px;
}

.first,
.second,
.third {
  position: relative;
}

.main p {
  font-weight: bold;
  font-size: 23px;
  margin: 0;
  letter-spacing: 3px;
  position: relative;
  z-index: 3;
}

.main h2,
.main h3 {
  font-weight: bold;
  font-size: 23px;
  letter-spacing: 3px;
  margin: 0;
  position: absolute;
}

.main h2 {
  color: rgb(173, 173, 173);
  z-index: 2;
}

.main h3 {
  color: rgb(221, 221, 221);
  z-index: 1;
}

.main {
  justify-content: space-between;
  margin-right: auto;
  margin-left: auto;
}

.why h3 {
  font-size: 30px;
  letter-spacing: 3px;
  position: relative;
  z-index: 2;
}

.why {
  position: relative;
}

.why h2 {
  position: absolute;
  top: -25px;
  font-size: 60px;
  color: white;
  margin: 0;
  z-index: 1;
  letter-spacing: 8px;
}

.little {
  position: absolute;
  border: 1px solid black;
  top: 60px;
  z-index: 1;
  height: 130px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.title {
  position: relative;
}

.title h1 {
  position: relative;
  z-index: 2;
}

.title h2 {
  position: absolute;
  top: 20px;
  color: white;
  margin: 0;
  z-index: 1;
}

.service {
  background-color: rgb(245, 245, 245);
}

.list p {
  background-color: white;
  border: 1px solid rgb(15, 15, 15);
  margin-left: 10px;
  box-shadow: 3px 3px 0px rgb(15, 15, 15);
}

.list h2 {
  background-color: white;
  border: 1px solid rgb(15, 15, 15);
  box-shadow: 3px 3px 0px rgb(15, 15, 15);
  margin-left: 10px;
  margin-top: 15px;
  position: relative;
  z-index: 2;
}

.list {
  padding-bottom: 150px;
  position: relative;
  z-index: 2;
}

@media screen and (max-width: 950px) {
  .top h2 {
    left: 10px;
    letter-spacing: 1px;
    font-size: 40px;
  }

  .titleR h2 {
    left: 10px;
    letter-spacing: 0;
    font-size: 40px;
  }

  .first,
  .second,
  .third {
    display: flex;
    align-items: center;
    padding-bottom: 30px;
    margin-left: 30px;
  }

  .main {
    margin-top: 50px;
  }

  .main h2 {
    top: 23px;
    left: 80px;
  }

  .main h3 {
    top: 30px;
    left: 80px;
  }

  .main img {
    margin-right: 10px;
  }

  .why h2 {
    left: 10%;
  }

  .why h3 {
    margin-top: 100px;
  }

  .little {
    width: 85%;
    left: 17px;
    letter-spacing: 1px;
    padding: 10px;
  }

  .title h2 {
    font-size: 40px;
    left: 5%;
  }

  .title {
    padding-top: 20px;
    padding-bottom: 5px;
  }

  .list p {
    letter-spacing: 2px;
  }

  .one p {
    width: 120px;
  }

  .two p {
    width: 190px;
  }

  .three p {
    width: 260px;
  }

  .list h2 {
    letter-spacing: 2px;
    font-size: 20px;
  }

  .one h2 {
    width: 170px;
  }

  .two h2 {
    width: 220px;
  }

  .three h2 {
    width: 330px;
  }
}

@media screen and (min-width: 951px) {
  .top h2 {
    left: 25%;
    font-size: 50px;
    letter-spacing: 2px;
  }

  .main {
    display: flex;
    width: 65%;
    margin-top: 80px;
  }

  .main p {
    padding-bottom: 50px;
  }

  .main h2 {
    top:82px;
  }

  .main h3 {
    top: 90px;
  }

  .why h2 {
    left: 36%;
  }

  .why h3 {
    margin-top: 130px;
  }

  .little {
    width: 95%;
    left: 40px;
    letter-spacing: 5px;
    padding-right: 15px;
  }

  .title h2 {
    font-size: 60px;
    left: 28%;
  }

  .title {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .description {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
  }

  .list p {
    letter-spacing: 3px;
  }

  .one p {
    width: 140px;
  }

  .two p {
    width: 220px;
  }

  .three p {
    width: 280px;
  }

  .list h2 {
    letter-spacing: 5px;
  }

  .one h2 {
    width: 200px;
  }

  .two h2 {
    width: 240px;
  }

  .three h2 {
    width: 430px;
  }
}
</style>
